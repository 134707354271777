import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import cn from 'classnames'

import styles from './SmallImageSection.module.scss'

export default ({ heading, images }) => {
  return (
    <FancyShow>
      <Container className={styles.container}>
        <Row>
          <Col
            col={{ md: 10 }}
            offset={{ md: 1 }}
          >
            <h3>{heading}</h3>
          </Col>
          <Col
            col={{ md: 10 }}
            offset={{ md: 1 }}
            className='px-0'
          >
            <div className={cn(
              styles.outerSlider
            )}>
              <div className={styles.innerSlider}>
                {images && images.map(({ image }, key) => {
                  return (
                    <div className='my-2 d-flex align-items-center' key={key}>
                      <img src={image} />
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </FancyShow>
  )
}
